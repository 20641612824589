<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <ButtonDefinitive
      label="imprimir"
      @click="downloadFile"
    />
    <ButtonDefinitive
      variant='tertiary'
      label="Volver al home"
      @click="sendToHome"
    />
  </div>
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'
import { downloadGuide } from "../../../services/guias";

export default {
  name: "OrderConfirmationFooter",
  components: { ButtonDefinitive },
  methods: {
    async downloadFile() {
      try {
        this.$swal.showLoading();
        await downloadGuide(
          this.payment.orderId,
          `Programado ${this.payment.orderId}`
        );
        this.$swal.close();
        this.trackEvent('click_programado_descargar_guia', 'programado')
      } catch (error) {
        this.showError("Error al descargar la guia");
        console.error(error);
        this.trackEvent('click_programado_descargar_guia_error', 'programado')
      }
    },
    sendToTracking() {
      window.location.href = `${process.env.VUE_APP_WEBURL}mis-envios`;
    },
    sendToHome() {
      this.$store.dispatch("STYLE_HOME_DIV");
      window.location.href = process.env.VUE_APP_WEBURL;
    },
  },
  computed: {
    payment() {
      return this.$store.getters.PAYMENT_RESULT;
    }
  },
};
</script>

<style></style>
