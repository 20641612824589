<template>
  <div class="row">
    <div class="col s12">
      <div class="row d-flex align-items-center">
        <div class="col s12">
          <input-district
            id="originDistrict"
            text="Lo recogemos en"
            @getDistrictId="setOriginDistrict"
            serviceType="2"
            v-show="type === 'single'"
            @input="originDisctrictId = 0"
          ></input-district>
          <input-district
            id="destinationDistrict"
            text="Lo entregamos en"
            @getDistrictId="setDestinationDistrict"
            serviceType="2"
            v-show="type === 'single'"
            @input="destinationDistrictId = 0"
          ></input-district>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col s12">
          <div>
    <ul>
      <li v-for="zone in zones" :key="zone.regiones">{{ zone.regiones }}</li>
    </ul>
  </div>
          <div
            class="c-nav c-nav--floating-footer c-nav--floating-footer-sticky__spaceButtonPair"
          >
            <button
              class="c-button c-button--primary c-button--width-fuid c-button--mobile-small waves-effect"
              v-on:click="handleSubmit()"
            >
              Calcular tarifa
            </button>
            <button
              class="c-button c-button--width-fuid c-button--flat c-button--mobile-small waves-effect"
              style="border: solid 1px"
              v-on:click="reset()"
            >
              Descargar tarifario
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import InputDistrict from "../General/InputDistrict.vue";
import UploadZone from "../General/UploadZone.vue";
import { readFileProgrammed } from "../../services/massiveLoad";
import { zones } from "@/domain/data/zones.js";

export default {
  name: "QuoterCmp",
  components: { InputDistrict, UploadZone },
  data() {
    return {
      originDisctrictId: null,
      destinationDistrictId: null,
      height: 0,
      deep: 0,
      width: 0,
      weight: 1,
      total: 0,
      maxHeight: 190,
      maxDeep: 150,
      maxWidth: 95,
      maxWeight: 25,
      type: "single",
      excelFile: [],
      lstDestination: null,
      responseMultiService: null,
      showModalDetailQuoteProgrammed: false,
      zones: zones,
    };
  },
  methods: {
    setExcelFile(files) {
      this.excelFile = files;
    },
    async downloadExcel() {
      this.showLoading();
      await this.$store
        .dispatch("GET_EXCEL_TEMPLATE_PROGRAMMED")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const url = window.URL.createObjectURL(new Blob([success.data]));
            const link = document.createElement("a");
            link.href = url;

            link.setAttribute(
              "download",
              `SCHARFF_PROGRAMADO_${this.getCurrentStringDate()}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const catchMsg = this.catchStandardErrorMsg(error);
          this.$swal.close();
          this.showError(catchMsg);
        });
    },
    setOriginDistrict(id) {
      this.originDisctrictId = id;
    },
    setDestinationDistrict(id) {
      this.destinationDistrictId = id;
    },
    setModel() {
      const fareRequest = {
        PackageValue: 0,
        PackageWidth: this.width,
        PackageHeight: this.height,
        PackageDeep: this.deep,
        PackageWeight: this.weight,
        PackageType: "Personalizado",
        isMoreThan10: false,
        IsServiceExpress: false,
        IsServiceProgrammed: true,
        isServiceScharffPoint: false,
        OriginDistrictId: this.originDisctrictId.id,
        DestinationDistrictId: this.destinationDistrictId.id,
        ProgrammedServiceDate: null,
        IsUsingSubscription: false,
      };
      return fareRequest;
    },
    async handleSubmit() {
      if (!this.validateInput()) {
        return;
      }
      if (this.type === "single") {
        const obj = this.setModel();
        this.$store
          .dispatch("PROGRAMMED_CALCULATE_FARE", obj)
          .then((response) => {
            this.total = response.totalFare;
          })
          .catch((error) => {
            this.$swal({
              title: "Error!",
              text: error,
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          });
      } else if (this.excelFile.length > 0) {
        let destinations = null;
        let valid = false;
        const formData = new FormData();
        this.excelFile.forEach((f) => {
          formData.append("file", f);
          formData.append("originDistrict", this.originDisctrictId.id);
        });
        const response = await readFileProgrammed(formData);
        if (response.status === 200) {
          valid = true;
          destinations = response.data;
          this.responseMultiService = response.data;
        } else {
          this.showError(response.data.message);
        }

        if (valid === true) {
          const sumall = destinations
            .map((item) => item.fare)
            .reduce((prev, curr) => prev + curr, 0);
          this.total = sumall;
          this.$swal.close();
          // await this.processExcelData(destinations);
        }
      } else {
        this.$swal({
          title: "Error!",
          text: "Debe cargar el excel, puedes descargar el excel en caso de no tenerlo",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    },
    reset() {
      this.originDisctrictId = "";
      this.destinationDistrictId = "";
      this.height = 0;
      this.deep = 0;
      this.width = 0;
      this.weight = 0;
      this.total = 0;
      this.excelFile = [];
      this.$refs.fileUploadZone.$refs.pond.removeFiles();
      document.getElementById("originDistrict").value = "";
      document.getElementById("destinationDistrict").value = "";
    },
    validateInput() {
      if (!this.originDisctrictId) {
        this.$swal({
          title: "Error!",
          text: "Debe ingresar el distrito de Origen",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
        return false;
      }
      if (this.type === "single") {
        if (!this.destinationDistrictId) {
          this.$swal({
            title: "Error!",
            text: "Debe ingresar el distrito de envio",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
          return false;
        }
        if (this.height === 0) {
          this.$swal({
            title: "Error!",
            text: "Debe ingresar una altura",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
          return false;
        }

        if (this.height > this.maxHeight) {
          this.showError(`La altura no puede ser mayor a ${this.maxHeight} cm`);
          this.height = this.maxHeight;
          return false;
        }

        if (this.deep === 0) {
          this.$swal({
            title: "Error!",
            text: "Debe ingresar el largo",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
          return false;
        }

        if (this.deep > this.maxDeep) {
          this.showError(`El largo no puede ser mayor a ${this.maxDeep} cm`);
          this.deep = this.maxDeep;
          return false;
        }

        if (this.width === 0) {
          this.$swal({
            title: "Error!",
            text: "Debe ingresar el ancho del paquete",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
          return false;
        }

        if (this.width > this.maxWidth) {
          this.showError(`El ancho no puede ser mayor a ${this.maxWidth} cm`);
          this.width = this.maxWidth;
          return false;
        }

        if (this.weight === 0) {
          this.$swal({
            title: "Error!",
            text: "Debe ingresar el peso del paquete",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
          return false;
        }

        if (this.weight > this.maxWeight) {
          this.showError(`El peso no puede ser mayor a ${this.maxWeight} kg`);
          this.weight = this.maxWeight;
          return false;
        }
      } else if (this.excelFile.length < 1) {
        this.$swal({
          title: "Error!",
          text: "Debe cargar el excel, puedes descargar el excel en caso de no tenerlo",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }

      return true;
    },
    isNumber(evnt) {
      const evt = evnt || window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
        return false;
      }
      return true;
    },
    closeModal() {
      const modal = document.querySelector("#modalCalculatorScheduledDelivery");
      const instance2 = M.Modal.getInstance(modal);
      instance2.close();
    },
  },
  mounted() {
    this.$nextTick(async () => {
      // this.initTabs();
    });
  },
};
</script>

<style>
td {
  border: solid 0.5px #c9c9c9 !important;
}
</style>
