<template>
  <article id="c-search-inputs" class="c-content--sendPoint c-scrollbar">
    <div class="c-content--app_container">
      <p class='text-xl-bold' style='padding-bottom: 16px'>¿Quién envía?</p>
      <DestinationContactButton
        @click="openContactModal('origin')"
        :destinationContact='originContact'
      />

      <p class='text-xl-bold' style='padding-bottom: 16px; padding-top: 48px;'>¿De dónde recogemos tus paquetes?</p>
      <SelectAddressButton
        @click='openAddressesModal()'
        :addressData='originAddressOfOrder'
      />

    </div>
  </article>
</template>

<script>
import M from "materialize-css";
import DestinationContactButton from "../../Button/DestinationContactButton.vue"
import SelectAddressButton from "../../Button/SelectAddressButton.vue"

export default {
  name: "Step2-Content",
  components: {
    DestinationContactButton,
    SelectAddressButton,
  },
  data() {
    return {
      isOriginFocused: false,
      originAddress: {
        description: "",
        lat: null,
        lng: null,
        favorite: false,
        addressDetail: "",
      },
      districtId: 0,
      lstDistricts: [],
    };
  },
  watch: {
    originAddress: {
      handler() {
        this.saveOriginAddress();
      },
      deep: true,
    },
  },
  methods: {
    AddAddressFavorite(type) {
      if (type === "origin") {
        this.$eventBus.$emit(
          "updateAddressInput",
          this.originAddress.description
        );
        this.$store.dispatch("HELPER_SET_ADD_ADDRESS", this.originAddress);
        this.originAddress.favorite = true;
      }
      const modalAddAddress = document.querySelector("#modal-add-address");
      const instance = M.Modal.init(modalAddAddress, { dismissible: false });
      instance.open();
    },
    editOriginContact() {
      this.openContactModal("origin");
    },
    openContactModal(type) {
      this.trackEvent('programado_paso_2_click_boton_contacto', 'programado')
      this.showLoading();
      this.$store
        .dispatch("GET_CONTACTS")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const contacts = success.data.result.list;
            this.$store.dispatch("GENERAL_SET_CONTACTS", contacts);
            this.$store.dispatch("HELPER_SET_MODAL_CONTACT_TYPE", type);
            const modalContactFavorite =
              document.querySelector("#modal-contact-origin");
            const instance = M.Modal.init(modalContactFavorite, {
              dismissible: false,
            });
            instance.open();
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const msg = error?.data?.message || "Ocurrio un error.";
          this.showError(msg);
        });
    },
    async openAddressesModal() {
      this.trackEvent('programado_paso_2_click_boton_direcciones', 'programado')
      this.showLoading();
      await this.$store.dispatch('GET_FAVORITE_LOCATIONS')
      const modalAddressFavorite = document.querySelector('#modal-favorite-address')
      const instance = M.Modal.init(modalAddressFavorite, {
        dismissible: false
      })
      instance.open()
      this.$swal.close()
    },
    setOriginDistrict(id) {
      this.districtId = id;
      this.$store.dispatch("PROGRAMMED_ORDER_ORIGIN_DISTRICT", id);
    },

    setAddressDetail() {
      this.$store.dispatch(
        "PROGRAMMED_ORDER_ORIGIN_ADDRESS_DETAIL",
        this.addressDetail
      );
    },
    saveOriginAddress() {
      this.trackEvent('programado_paso_2_elegir_direccion', 'programado')
      console.log(this.originAddress)
      this.$store.dispatch(
        "PROGRAMMED_ORDER_ORIGIN_ADDRESS",
        this.originAddress
      );
    },
    getOriginAddressData(addressData, placeResult) {
      this.originAddress.description = placeResult.formatted_address;
      this.originAddress.lat = addressData.latitude;
      this.originAddress.lng = addressData.longitude;
      this.isOriginFocused = false;
      const draggedHandler = (event) => {
        const pos = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        const $this = this;
        this.map.geocodeLocation(pos, (result) => {
          $this.originAddress.description = result.formatted;
          $this.originAddress.lat = result.position.lat;
          $this.originAddress.lng = result.position.lng;
          $this.originAddress.favorite = false;
          $this.$refs.originAddress.update(result.formatted);
          $this.$swal.close();
        });
      };
      this.map.setMarker(
        { Lat: addressData.latitude, Lng: addressData.longitude },
        null,
        true,
        draggedHandler
      );
      this.saveOriginAddress();
    },
    getOriginSelectedAddress(favoriteLocation) {
      this.originAddress.description = favoriteLocation.address;
      this.originAddress.lat = favoriteLocation.location.Lat;
      this.originAddress.lng = favoriteLocation.location.Lng;
      this.addressDetail = favoriteLocation.reference;
      this.$refs.originAddress.update(favoriteLocation.address);
      this.isOriginFocused = false;
      const draggedHandler = (event) => {
        const pos = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        const $this = this;
        this.map.geocodeLocation(pos, async (result) => {
          $this.originAddress.description = result.formatted;
          const district = result.formatted
            .split(",")[1]
            .replace(/[0-9]+/g, "")
            .trim();
          await $this.getDistricts($this.removeAccents(`${district}`));
          if ($this.lstDistricts.length > 0) {
            $this.districtId = $this.lstDistricts[0].id;
            this.$refs.inputDistrict.districtVaule = $this.lstDistricts[0].text;
          } else {
            $this.districtId = 0;
            this.$refs.inputDistrict.districtVaule = "";
          }
          $this.originAddress.lat = result.position.lat;
          $this.originAddress.lng = result.position.lng;
          $this.originAddress.favorite = false;
          $this.$refs.originAddress.update(result.formatted);
          $this.$swal.close();
        });
      };
      this.map.setMarker(favoriteLocation.location, null, true, draggedHandler);
      this.saveOriginAddress();
    },

    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    async getDistricts(district) {
      // this.showLoading("Cargando...");
      return this.$store
        .dispatch("GET_DISTRICTS", district)
        .then((response) => {
          this.lstDistricts = response;
        });
    },
  },
  mounted() {
    this.$nextTick(() => {});
    this.$eventBus.$on("programmedSetOriginAddressDetail", () => {
      this.setAddressDetail();
    });
  },
  computed: {
    originContact() {
      return this.$store.getters.PROGRAMMED_ORIGIN_CONTACT;
    },
    originAddressOfOrder() {
      return this.$store.getters.SCHARFFPOINT_ORDER_ORIGIN
    },
    order() {
      return this.$store.getters.PROGRAMMED_ORDER;
    },
    map() {
      return this.$store.getters.PROGRAMMED_MAP;
    },
  },
};
</script>
