<template>
  <div class="c-box--calculator">
    <div class="c-calculator">
      <div class="c-calculator__container">
        <quoter />
      </div>
    </div>
  </div>
</template>
<script>
import Quoter from "./Quoter.vue";

export default {
  components: {
    Quoter,
  },
};
</script>
<style lang=""></style>
