<template>
  <div class="c-nav c-nav--floating-footer-sticky">
    <ButtonDefinitive
      @click='callValidation'
    >
      <template v-slot:custom-content>
        <div class='text-md-bold' style='color: #fff;'>Confirmar envío</div>
      </template>
    </ButtonDefinitive>
  </div>
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: "Step4Footer",
  components: { ButtonDefinitive },
  methods: {
    callValidation() {      
      this.$eventBus.$emit("validateProgrammedStep4");
    },
  },
  computed: {
    IS_AN_EMBASSY_USER() {
      return this.$store.getters.IS_AN_EMBASSY_USER;
    },
    fare() {
      return this.$store.getters.PROGRAMMED_FARE;
    },
    order() {
      return this.$store.getters.PROGRAMMED_ORDER;
    },
    originalFare() {
      return this.order.Destination.map((item) => item.fare).reduce(
        (prev, curr) => prev + curr,
        0
      );
    },
    coupon() {
      return this.$store.getters.PROGRAMMED_COUPON;
    },
  },
  created() {
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
      this.$nextTick(() => {
        let appContainer = document.querySelector('.c-content--app_container');
        if (appContainer) {
          appContainer.classList.add('ios-safari');
        }
      });
    }
  }
};
</script>

<style>
@media screen and (max-device-width: 580px) {
  .c-content--app_container.ios-safari {
    padding-bottom:10em;
  }
}
</style>