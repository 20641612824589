<!-- eslint-disable max-len -->
<template>
  <article class="c-content--sendPoint c-scrollbar order_confirmed">

    <!-- Header -->
    <div class='order_confirmed--header' @click='seeDetails'>
      <h1 class='text-4xl-bold' style='color: #68002B;'>¡Listo, envío creado!</h1>
      <div v-if='!IS_AN_EMBASSY_USER' style='display: flex; padding: 8px 12px; gap: 8px; border-radius: 12px; background: #FFC1C2; width: max-content'>
        <div class='text-m-regular' style='color: #68002B'>Pagaste:</div>
        <div v-if='coupon && coupon.code' style='display: flex; gap: 8px;'>
          <div class='text-m-regular'style='color: #68002B; text-decoration: line-through'>S/ {{ mountPaid.mount.toFixed(2) }}</div>
          <div class='text-m-regular'style='color: #68002B'>S/ {{ mountPaid.mountWithDiscount.toFixed(2) }}</div>
        </div>
        <div v-else class='text-m-regular'style='color: #68002B'>S/ {{ mountPaid.mount.toFixed(2) }}</div>
      </div>
    </div>
    <!-- Fecha de recojo -->
    <div class='order_confirmed--info'>
      <div class='text-xl-bold'>Fecha de recojo</div>
      <ChooseOptionButton :isSelectable='false'>
        <template v-slot:icon>
          <Icon name='Profile' strokeColor='#FF6568' />
        </template>
        <template v-slot:content>
          <div class='text-m-regular'>{{ deliveryDate }}</div>
        </template>
      </ChooseOptionButton>
    </div>
    <!-- ¿Quién envía? -->
    <div class='order_confirmed--info'>
      <div class='text-xl-bold'>¿Quién envía?</div>
      <ChooseOptionButton :isSelectable='false'>
        <template v-slot:icon>
          <Icon name='Profile' strokeColor='#FF6568' />
        </template>
        <template v-slot:content>
          <div class='text-m-regular'>{{ contactOrigin.name }}</div>
        </template>
      </ChooseOptionButton>
    </div>
    <!-- Dirección de recojo -->
    <div v-if='origin.scharffPoint' class='order_confirmed--info'>
      <div class='text-xl-bold'>Dirección de recojo</div>
      <div style='display: flex; padding: 16px; flex-direction: column; gap: 12px; border-radius: 16px; border: 1px solid #D0D0D0'>
        <div class='text-m-bold'>{{ origin.scharffPoint.Name }}</div>
        <div class=''>
          <div class='text-s-regular'>{{ origin.scharffPoint.Address }}</div>
          <div class='text-s-regular' style='color: #737373;'>{{`Horario de atención: ${origin.scharffPoint.OpenTime.substring(0,5)} - ${origin.scharffPoint.CloseTime.substring(0, 5)}`}}</div>
        </div>
      </div>
    </div>
    <div v-else class='order_confirmed--info'>
      <div class='text-xl-bold'>Dirección de recojo</div>
      <ChooseOptionButton :isSelectable='false'>
        <template v-slot:icon>
          <Icon name='Pin13' strokeColor='#FF6568' />
        </template>
        <template v-slot:content>
          <div>
            <div class='text-m-regular'>{{ origin.description }}</div>
            <div class='text-s-regular' style='color: #737373'>{{ origin.name }}</div>
          </div>
        </template>
      </ChooseOptionButton>
    </div>
    <!-- Paquetes -->
    <div class='order_confirmed--info'>
      <div class='text-xl-bold'>Paquetes</div>
      <div class='order_confirmed--info--packages'>
        <div style='min-width: 300px' class='' v-for='(item, index) in lstDestination' v-bind:key='index'>
          <PackageSummary
            :packageDataIndex='index'
            :includeEditButton='false'
            :includeDeleteButton='false'
            :packageData='item'
          />
        </div>
      </div>
    </div>
    <!-- Tipo de comprobante -->
    <div class='order_confirmed--info' v-if='!IS_AN_EMBASSY_USER'>
      <div class='text-xl-bold'>Tipo de comprobante</div>
      <ChooseOptionButton :isSelectable='false'>
        <template v-slot:icon>
          <Icon name='Receipt-bill' strokeColor='#FF6568' />
        </template>
        <template v-slot:content>
          <div class='text-m-regular'>{{ voucher.typeVoucher }}</div>
        </template>
      </ChooseOptionButton>
    </div>
    <!-- Método de pago -->
    <div class='order_confirmed--info' v-if='payment.cardNumber && !IS_AN_EMBASSY_USER'>
      <div class='text-xl-bold'>Método de pago</div>
      <ChooseOptionButton :isSelectable='false'>
        <template v-slot:icon>
          <Icon name='BankCardDouble' strokeColor='#FF6568' />
        </template>
        <template v-slot:content>
          <div class='text-m-regular'>{{ payment.cardBrand }} {{ payment.cardNumber }}</div>
        </template>
      </ChooseOptionButton>
    </div>
    <!-- ¿Tienes un cupón de descuento? -->
    <div class='order_confirmed--info' v-if='!IS_AN_EMBASSY_USER'>
      <div class='text-xl-bold'>¿Tienes un cupón de descuento?</div>
      <ChooseOptionButton :isSelectable='false'>
        <template v-slot:icon>
          <Icon name='Discount-ticket' strokeColor='#FF6568' />
        </template>
        <template v-slot:content>
          <div class='text-m-regular'>{{ coupon ? `${coupon.code} (${coupon.discountMessage})` : 'Sin cupón' }}</div>
        </template>
      </ChooseOptionButton>
    </div>
  </article>
</template>

<script>
import Icon from '@/components/Icon.vue'
import ChooseOptionButton from '@/components/Button/ChooseOptionButton.vue'
import PackageSummary from '@/components/PackageSummary.vue'

export default {
  name: "OrderConfirmationContent",
  components: {
    Icon,
    ChooseOptionButton,
    PackageSummary,
  },
  computed: {
    IS_AN_EMBASSY_USER() {
      return this.$store.getters.IS_AN_EMBASSY_USER;
    },
    deliveryDate() {
      return this.$store.getters.PROGRAMMED_ORDER_DELIVERY_DATE;
    },
    lstDestination() {
      return this.$store.getters.PROGRAMMED_ORDER_DESTINATIONN;
    },
    origin() {
      return this.$store.getters.PROGRAMMED_ORDER_ORIGIN;
    },
    contactOrigin() {
      return this.$store.getters.PROGRAMMED_ORIGIN_CONTACT;
    },
    productValue() {
      return this.$store.getters.PROGRAMME_PRODUCT_VALUE;
    },
    payment() {
      return this.$store.getters.PAYMENT_RESULT;
    },
    voucher() {
      return this.$store.getters.SCHARFFPOINT_VOUCHER;
    },
    card() {
      return this.$store.getters.SCHARFFPOINT_CARD;
    },
    coupon() {
      return this.$store.getters.SCHARFFPOINT_COUPON;
    },
    mountPaid() {
      const orders = this.$store.getters.SCHARFFPOINT_ORDER_DESTINATION;
      const mount = orders.reduce((previous, current) => {
        return previous + current.fare
      }, 0)
      const mountWithDiscount = orders.reduce((previous, current) => {
        return previous + parseFloat(current.discountFare)
      }, 0)
      return {mount, mountWithDiscount}
    },
  },
  methods: {
    seeDetails() {
      console.log('1', JSON.parse(JSON.stringify(this.lstDestination)))
      console.log('2', JSON.parse(JSON.stringify(this.origin)))
      console.log('3', JSON.parse(JSON.stringify(this.contactOrigin)))
      console.log('4', this.productValue)
      console.log('5', this.type)
      console.log('6', JSON.parse(JSON.stringify(this.payment)))
      console.log('7', this.voucher)
      console.log('8', this.card)
      console.log('9', this.coupon)
    },
  },
};
</script>

<style></style>
