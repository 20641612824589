<!-- eslint-disable max-len -->
<template>
  <div
    v-if="lstDestination.length === 0"
    :id="id"
    class="c-grid c-section c-content--sendPoint c-scrollbar flex-column justify-content-center"
  >
    <div class="c-grid--service c-content--package-add">
      <div class="c-section--content-article text-center">
        <article class="c-grid--services__item text-center">
          <ul>
            <li class='text-l-bold'>
              Agrega tus paquetes uno por uno <br />
              a tu pedido
            </li>
            <li class='text-m-regular' style='color: #737373; padding-top: 8px;'>
              Recomendado para 1 - 5 paquetes
            </li>
          </ul>
        </article>
        <div class="pt-1" style='width: 100%; display: flex; justify-content: center;'>
          <div style='width: 230px'>
            <ButtonDefinitive
              size='m'
              variant='ghost'
              label='Agregar paquete'
              @click="addPackage"
            >
              <template v-slot:icon>
                <Icon name='Plus4' />
              </template>
            </ButtonDefinitive>
          </div>
        </div>

        <article class="c-grid--services__item pb-1">

          <!-- Separator -->
          <div style='width: 100%; height: 68px; padding-top: 24px; padding-bottom: 24px; justify-content: center; align-items: center; display: inline-flex'>
            <div style='flex: 1 1 0; height: 1px; background: #EBEBEB; border-radius: 360px'></div>
            <div style='padding-left: 8px; padding-right: 8px; flex-direction: column; justify-content: center; align-items: center; gap: 8px; display: inline-flex'>
              <div style='justify-content: center; align-items: center; gap: 8px; display: inline-flex'>
                <div style='color: #737373; font-size: 14px; font-weight: 400; line-height: 20px; word-wrap: break-word'>o</div>
              </div>
            </div>
            <div style='flex: 1 1 0; height: 1px; background: #EBEBEB; border-radius: 360px'></div>
          </div>

          <div class='text-l-bold' style='padding-bottom: 8px;'>Agrega tus paquetes de manera masiva con nuestra plantilla</div>
          <div class='text-m-regular' style='padding-bottom: 20px; color: #737373'>Recomendado para más de 5 paquetes</div>
          <div style='width: 230px; margin: auto;'>
            <ButtonDefinitive
              size='m'
              variant='ghost'
              label='Descargar plantilla'
              @click="downloadExcel"
            >
              <template v-slot:icon>
                <Icon name='Down-Line' />
              </template>
            </ButtonDefinitive>
          </div>
          <div style='padding: 12px 0 16px 0'>
            <InformativeMessage message="Descarga siempre la última versión." />
          </div>
          <upload-zone
            @getFiles="setExcelFile"
            :filetypes="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx'"
            :allowMultiple="false"
          />
        </article>
      </div>
    </div>
  </div>

  <div v-else class="c-section--content-shadow c-content--sendPoint c-scrollbar c-content--app_container">
    <div style='padding-bottom: 16px' class="c-modal m-0" v-for="(item, index) in lstDestination" v-bind:key="index">
      <PackageSummary
        :packageDataIndex='index'
        :packageData='item'
        @onEdit="editDestinationAddress(item, index)"
        @onDelete="deleteDestinationAddress(index)"
      />
    </div>
  </div>
</template>

<script>
import UploadZone from "../../General/UploadZone.vue";
import Icon from '@/components/Icon.vue'
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'
import PackageSummary from '@/components/PackageSummary.vue'
import InformativeMessage from '@/components/General/InformativeMessage.vue'

export default {
  name: "Step3-Content",
  components: {
    UploadZone,
    Icon,
    ButtonDefinitive,
    PackageSummary,
    InformativeMessage,
  },
  props: {
    id: String,
  },
  data() {
    return {};
  },
  methods: {
    setExcelFile(files) {
      this.excelFile = files;
      this.$store.dispatch("PROGRAMMED_FILE", this.excelFile);
      this.trackEvent('programado_paso_3_subir_plantilla', 'programado')
    },
    async downloadExcel() {
      this.showLoading();
      await this.$store
        .dispatch("GET_EXCEL_TEMPLATE_PROGRAMMED")
        .then((success) => {
          this.$swal.close();
          if (success.status === 200) {
            const url = window.URL.createObjectURL(new Blob([success.data]));
            const link = document.createElement("a");
            link.href = url;

            link.setAttribute(
              "download",
              `SCHARFF_PROGRAMADO_${this.getCurrentStringDate()}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
          } else {
            this.showError(success.data.message);
          }
        })
        .catch((error) => {
          const catchMsg = this.catchStandardErrorMsg(error);
          this.$swal.close();
          this.showError(catchMsg);
        });
        this.trackEvent('click_programado_paso_3_descargar_plantilla', 'programado')
    },
    deleteDestinationAddress(index) {
      this.$store.dispatch("PROGRAMMED_ORDER_DELETE_DESTINATION", index);
    },
    editDestinationAddress(destination, index) {
      this.$store.dispatch("PROGRAMMED_ORDER_SET_INDEX_DESTINATION", index);
      this.$eventBus.$emit("programmedSetDataDestinationModal", destination);
      const modalpackage = document.querySelector("#modal-package");
      modalpackage.classList.add("is-active");
      document.getElementById("programmed-step-3-modal-pop").value =
        destination.destinationAddress.districtId.text;
    },
    addPackage() {
      this.$eventBus.$emit("programmedClearDataDestinationModal");
      this.$store.dispatch("PROGRAMMED_ORDER_SET_INDEX_DESTINATION", 0);
      const modalpackage = document.querySelector("#modal-package");
      document.getElementById("programmed-step-3-modal-pop").value = "";
      modalpackage.classList.add("is-active");
      this.trackEvent('click_programado_paso_3_agregar_paquete', 'programado')
    },



    printOrder(order) {
      console.log(JSON.parse(JSON.stringify(order)))
    }
  },
  computed: {
    lstDestination() {
      return this.$store.getters.PROGRAMMED_ORDER_DESTINATIONN;
    },
  },
};
</script>
