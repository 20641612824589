<template>
  <ButtonDefinitive
    label="Continuar"
    @click="next"
  />
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: "Step1-Footer",
  mounted() {
    this.$nextTick(() => {});
  },
  components: { ButtonDefinitive },
  methods: {
    next() {
      const date = this.$store.getters.PROGRAMMED_ORDER_DELIVERY_DATE;
      if (date !== "") {
        this.$store.dispatch("PROGRAMMED_PROGRESS_NAV_STEP", 2);
        this.trackEvent('click_programado_paso_1_continuar', 'programado')
      } else {
        this.showError("Se debe de seleccionar una fecha");
      }
    },
  },
};
</script>

<style></style>
