<template>
  <ButtonDefinitive
    label="Continuar"
    @click="next"
  />
</template>

<script>
import ButtonDefinitive from '@/components/Button/ButtonDefinitive.vue'

export default {
  name: "Step2-Footer",
  mounted() {
    this.$nextTick(() => {});
  },
  components: { ButtonDefinitive },
  methods: {
    next() {
      if (this.validateData() === "ok") {
        this.$store.dispatch("PROGRAMMED_PROGRESS_NAV_STEP", 3);
        this.$store.dispatch("STYLE_MAP_SAIO", false);
        this.$store.dispatch("STYLE_SHOW_PM_QUOTER", false);
        this.$store.dispatch("STYLE_PROGRAMMED_STEP_DIV_THREE_GENERAL");
        // this.$eventBus.$emit("programmedSetOriginAddressDetail");
        this.trackEvent('programado_paso_2_continuar', 'programado')
      } else {
        this.showError(this.validateData());
      }
    },
    validateData() {
      return this.$store.getters.PROGRAMMED_VALIDATE_STEP_2;
    },
  },
};
</script>

<style></style>
