<template>
  <article class="c-content--sendPoint c-scrollbar">
    <div class="c-content--app_container">
      <div class="c-text-center text-xl-bold">¿Cuándo recogemos tus paquetes?</div>
      <div>
        <app-calendar @getDate="getDataCalendar"></app-calendar>
      </div>
    </div>
  </article>
</template>

<script>
import AppCalendar from "../../General/AppCalendar.vue";

export default {
  components: {
    AppCalendar,
  },
  name: "Step1-Content",
  methods: {
    getDataCalendar(date) {
      this.$store.dispatch("PROGRAMMED_ORDER_DELIVER_DATE", date);
      this.$eventBus.$emit("setDateModal", date);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch("PROGRAMMED_PROGRESS_NAV_STEP", 1);
    });
  },
};
</script>

<style>
/* .c-content.c-content--app.c-content--mobile-spacing-top.c-content--full_height.pb-0 {
  padding-top: 7em;
} */
</style>
