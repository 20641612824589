<template>
  <div class="c-slider pt-0">
    <VueSlickCarousel v-bind="settingSlider">
      <div class="c-slider_item">
        <img src="~@/assets/img/service-cart-welcome.png" />
        <div class="">
          {{ textCarousel }}
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
  data() {
    return {
      settingSlider: {
        arrows: false,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 6000,
        cssEase: "linear",
      },
    };
  },
  components: {
    VueSlickCarousel,
  },
  computed: {
    progressNav() {
      return this.$store.getters.PROGRAMMED_PROGRESS_NAV;
    },
    textCarousel() {
      switch (this.progressNav.step) {
        case 1:
          return "¿Cuándo quieres que pasemos a recoger tu paquete? ¡Elige el día! Recuerda que puedes elegir recojos el mismo día hasta antes las 11 am.";

        case 2:
          return "Cuéntanos todos los datos del recojo del paquete.";

        case 3:
          return "Recuerda que puedes agregar 1 paquete o hasta 100 paquetes por carga.";

        case 4:
          return "Chequea y verifica el resumen de tu envío.";

        default:
          return "Recogemos tus paquetes y los enviamos a todo Lima y provincias.";
      }
    },
  },
  methods: {},
  mounted() {},
};
</script>
